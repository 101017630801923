<template>
  <div v-loading="assessLoading">
    <div class="profitRateResult" v-if="hasVal() && level != 'ad'">
      <el-popover
        placement="top"
        title=""
        width="700"
        trigger="hover"
        @show="setActiveTab"
      >
        <el-tabs v-model="activeTab" type="border-card">
          <!-- <el-tab-pane
            v-if="level == 'campaign'"
            label="商品经营分析"
            name="product_report"
          >
            <product-report-list
              :productReport="productReport('product_report')"
            ></product-report-list>
          </el-tab-pane> -->
          <el-tab-pane
            name="adProductReport"
            v-if="level == 'campaign'"
            :label="
              (level == 'campaign' ? '广告系列层级' : 'facebook订单') +
                '经营分析'
            "
          >
            <product-report-list
              :productReport="productReport('adProductReport')"
            ></product-report-list>
          </el-tab-pane>
          <el-tab-pane
            name="localOrderReport"
            label="本地订单经营分析"
            v-if="level == 'adset'"
          >
            <product-report-list
              :productReport="localOrderReport()"
            ></product-report-list>
          </el-tab-pane>
        </el-tabs>
        <div slot="reference">
          <div class="txt_img">
            <span v-if="level == 'campaign'">{{ keyFlagDesc() }}</span>
            <img
              :src="imgSrc[profitRateResult]"
              alt=""
            />
          </div>
        </div>
      </el-popover>
    </div>
    <div v-else>--</div>
  </div>
</template>

<script>
import productReportList from "@/views/adManagement/components/table/productReportList";
import { ProductReport } from "./ProductReport.js";
import HIGHT from '@/assets/icon/level1.png'
import MID from '@/assets/icon/level2.png'
import LOW from '@/assets/icon/level3.png'
import LOW2 from '@/assets/icon/level4.png'
export default {
  components: {
    productReportList,
  },
  props: {
    value: {
      type: Object,
    },
    level: {
      type: String,
      default: "campaign",
    },
    assessLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      activeTab: this.level == 'campaign'?"adProductReport":'localOrderReport',
      imgSrc:{
        HIGHT,
        MID,
        LOW,
        LOW2
      }
    };
  },
  computed:{
    profitRateResult() {
      if (this.hasReports("adProductReport"))
        return this.productReport("adProductReport").profitRateResult;
      // if (this.hasReports("product_report"))
      //   return this.productReport("product_report").profitRateResult;
      return "";
    },
  },
  methods: {
    // profitRateResult() {
    //   if (this.hasReports("adProductReport"))
    //     return this.productReport("adProductReport").profitRateResult;
    //   if (this.hasReports("product_report"))
    //     return this.productReport("product_report").profitRateResult;
    //   return "";
    // },
    keyFlagDesc() {
      if (this.hasReports("adProductReport")) return "系列级";
      // if (this.hasReports("product_report")) return "商品级";
      return "";
    },
    keyFlag() {
      // let key = this.level == "campaign" ? "product_report" : "adProductReport";
      let key = "adProductReport";
      // if (!this.hasReports(key)) {
      //   key = "product_report";
      // }
      return key;
    },
    setActiveTab() {
      this.activeTab = this.keyFlag();
    },
    hasReports(key) {
      return (
        Object.keys(this.productReport(key)).length > 0 &&
        !this.productReport(key).nullreason
      );
    },
    hasVal() {
      return (
        // (this.hasReports("product_report") &&
        //   this.productReport("product_report").profitRateResult) ||
        (this.hasReports("adProductReport") &&
          this.productReport("adProductReport").profitRateResult)
      );
    },
    productReport(key) {
      if (this.value.row.level == "campaign") {
        return this.value.row[key] || {};
      } else {
        if (this.value.row[key] && Object.keys(this.value.row[key]).length) {
          let purchase = this.value.row.local_order?.validOrderCount ?? 0;
          let moneyRate =
            this.value.row[key].adCost / this.value.row.campaignCost;
          let spend = this.value.row.spend * moneyRate;
          // let purchase = this.value.row.local_order?`${this.value.row.local_order.validOrderCount}`:0
          return new ProductReport(
            this.value.row[key],
            // this.value.row.campaignPurchase,
            purchase,
            // this.value.row.campaignCost,
            spend
          );
        } else {
          return {};
        }
      }
    },
    localOrderReport() {
      if (!this.value.row["adProductReport"]) {
        return {};
      }
      let purchase = this.value.row.local_order?.validOrderCount ?? 0;
      let moneyRate =
        this.value.row["adProductReport"].adCost / this.value.row.campaignCost;
      let spend = this.value.row.spend * moneyRate;
      return new ProductReport(
        this.value.row["adProductReport"],
        purchase,
        spend
      );
    },
    // productReport(key) {
    //     if (this.value.row.level == "campaign") {
    //       return this.value.row[key] || {};
    //     } else {
    //       if (this.value.row[key] && Object.keys(this.value.row[key]).length) {
    //         // 判断广告组有没有订单
    //         let purchase;
    //         if(this.value.row.local_order && this.value.row.local_order.orderCount){
    //           // 如果存在订单
    //           let startDate = JSON.parse(localStorage.getItem('adDateRange')).dateRange[0];
    //           if(startDate.getTime()<new Date('2021-07-10').getTime()){
    //             // 如果开始时间在7.10之前
    //             purchase = this.value.row.object_actions || this.value.row.purchase || this.value.row.purchase_web;
    //           }else{
    //             // 开始时间是7.10之后
    //             purchase = this.value.row.local_order.validOrderCount || 0
    //           }
    //           // if(startDate)
    //         }else{
    //           // 如果不存在订单
    //           purchase = this.value.row.object_actions || this.value.row.purchase || this.value.row.purchase_web;
    //         }
    //         // return new ProductReport(
    //         //         this.value.row[key],
    //         //         this.value.row.campaignPurchase,
    //         //         purchase,
    //         //         this.value.row.campaignCost,
    //         //         this.value.row.spend
    //         // );
    //         return new ProductReport(
    //                 this.value.row[key],
    //                 purchase,
    //                 this.value.row.spend
    //         );
    //       } else {
    //         return {};
    //       }
    //     }
    // },
  },
  // watch:{
  //   assessLoading:{
  //     handler(){
  //       if(this.assessLoading){
  //         this.activeTab=this.keyFlag();
  //       }
  //     },
  //     immediate:true
  //   },
  // },
  mounted() {
    this.activeTab = this.keyFlag();
  },
};
</script>

<style lang="scss" scoped>
.txt_img{
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width:25px;
    height: 25px;
    margin-left: 5px;
  }
}
</style>
