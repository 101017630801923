export function ProductReport(from, targetAdsetPurchase, targetAdsetAdCost) {
    let _targetAdsetPurchase = (!!targetAdsetPurchase) ? targetAdsetPurchase : 0;//object_actions     purchase     purchase_web
    let _targetAdsetAdCost = (!!targetAdsetAdCost) ? targetAdsetAdCost : 0;//spend


    let productId = 0;// int(11) DEFAULT NULL COMMENT '商品ID',  //int
    let collId = 0;// int(11) DEFAULT NULL COMMENT '站点ID',  //int
    let orderDate = '';// date DEFAULT NULL COMMENT '订单日期',  //Date
    let orderCount = 0;// int(11) DEFAULT NULL COMMENT '有效订单数',  //int
    let aderId = 0;// varchar(255) DEFAULT NULL COMMENT '优化师ID',  //int
    let firstSendQty = 0;// int(10) DEFAULT '0' COMMENT '商品总数量',  //int
    let secondSendQty = 0;// int(10) DEFAULT '0' COMMENT '二次改派订单的商品数量',  //int
    let platform = 0;// varchar(255) DEFAULT NULL COMMENT '平台',  //String
    let buyPrice = 0;// decimal(10,2) DEFAULT NULL COMMENT '采购单价',//月报表是采购总价  //BigDecimal
    let adCost = 0;// decimal(10,2) DEFAULT NULL COMMENT '总广告费',  //BigDecimal
    let orderAmount = 0;// decimal(10,2) DEFAULT NULL COMMENT '总销售额',  //BigDecimal
    let id = '';// varchar(50) NOT NULL,  //String
    let areaId = 0;// int(11) DEFAULT NULL COMMENT '团队ID',  //int
    let goodsId = 0;// int(11) DEFAULT NULL COMMENT '产品ID',  //int
    let goodsName = '';// varchar(255) DEFAULT NULL COMMENT '产品名称',  //String
    let productName = '';// varchar(255) DEFAULT NULL COMMENT '商品名称',  //String
    let category = '';// varchar(255) DEFAULT NULL COMMENT '产品分类',  //String
    let categoryId = 0;// int(11) DEFAULT NULL COMMENT '分类ID',  //int
    let productType = '';// varchar(255) DEFAULT NULL COMMENT '商品类型',  //String
    let currencyId = 0;// int(11) DEFAULT NULL COMMENT '币种ID',  //int
    let chooserId = 0;// int(11) DEFAULT NULL COMMENT '选品ID',  //int
    let designerId = 0;// int(11) DEFAULT NULL COMMENT '设计师ID',  //int
    let designerName = 0;// int(11) DEFAULT NULL COMMENT '设计师ID',  //int
    let domain = '';// varchar(255) DEFAULT NULL COMMENT '商品域名',  //String
    let orgCode = '';// varchar(10) DEFAULT NULL COMMENT '组织代码',  //String
    let createTime = '';// datetime DEFAULT CURRENT_TIMESTAMP COMMENT '创建时间',  //Date
    let updateTime = '';// datetime DEFAULT NULL ON UPDATE CURRENT_TIMESTAMP COMMENT '更新时间',  //Date
    let invalid = 0;// tinyint(1) DEFAULT '0' COMMENT '是否有效，0有效，1无效',  //int
    let collType = 0;// int(2) NOT NULL DEFAULT '0' COMMENT '1：单页  2：shopify  3：聚合页 4：其他  5：商城3.0 6：vivishop 7：虾皮  8：头条',  //int
    let lowPrice = 0;// tinyint(1) DEFAULT '0' COMMENT '是否低价',  //int
    let isChange = 0;// tinyint(1) DEFAULT '0' COMMENT '是否改派',  //int
    let isTestProduct = 0;// tinyint(1) DEFAULT '0' COMMENT '是否测试品',  //int
    let codSignRate = 0;  //BigDecimal
    let onlineSignRate = 0;  //BigDecimal
    let preSignRate = 0;  //BigDecimal
    let logisticsFee = 0;  //BigDecimal
    let otherRate = 0;  //BigDecimal
    let highLine = 0;  //BigDecimal
    let midLine = 0;  //BigDecimal
    let lowLine = 0;  //BigDecimal
    let amountPerCustomer = 0;  //BigDecimal
    let countPerCustomer = 0;  //BigDecimal
    let roi = 0;  //BigDecimal
    let buyRate = 0;  //BigDecimal
    let adRate = 0;  //BigDecimal
    let logisticsRate = 0;  //BigDecimal
    let threeRate = 0;  //BigDecimal
    let hisSignRate = 0;  //BigDecimal
    let signRate = 0;  //BigDecimal
    let profitRate = 0;  //BigDecimal
    let profitRateResult = '';  //ProfitType
    let lowestRoi = 0;  //BigDecimal
    let aderName = '';
    let chooserName = '';
    let avgAdCost = 0;//平均广告费
    let adCriticalVal = 0;//广告临界值
    let profitRatePre = 0;  //毛利率
    let grossMarginRate = 0; //新的毛利率字段
    let originAvgAdCost = 0; //广告花费原始币种
    let originAdCriticalVal = 0;//广告临界值原始币种
    let exchangeRate = 1;//汇率
    let currencySign = '';//币种

    function init(from) {
        initBy(from);
        split();
        reCalc();
    }

    function initBy(from) {
        if (from) {
            productId = (!!from.productId) ? from.productId : productId;
            collId = (!!from.collId) ? from.collId : collId;
            orderDate = (!!from.orderDate) ? from.orderDate : orderDate;
            orderCount = (!!from.orderCount) ? from.orderCount : orderCount;
            aderId = (!!from.aderId) ? from.aderId : aderId;
            firstSendQty = (!!from.firstSendQty) ? from.firstSendQty : firstSendQty;
            secondSendQty = (!!from.secondSendQty) ? from.secondSendQty : secondSendQty;
            platform = (!!from.platform) ? from.platform : platform;
            adCost = (!!from.adCost) ? from.adCost : adCost;
            orderAmount = (!!from.orderAmount) ? from.orderAmount : orderAmount;
            id = (!!from.id) ? from.id : id;
            areaId = (!!from.areaId) ? from.areaId : areaId;
            goodsId = (!!from.goodsId) ? from.goodsId : goodsId;
            goodsName = (!!from.goodsName) ? from.goodsName : goodsName;
            productName = (!!from.productName) ? from.productName : productName;
            category = (!!from.category) ? from.category : category;
            categoryId = (!!from.categoryId) ? from.categoryId : categoryId;
            productType = (!!from.productType) ? from.productType : productType;
            currencyId = (!!from.currencyId) ? from.currencyId : currencyId;
            chooserId = (!!from.chooserId) ? from.chooserId : chooserId;
            designerId = (!!from.designerId) ? from.designerId : designerId;
            designerName = (!!from.designerName) ? from.designerName : designerName;
            domain = (!!from.domain) ? from.domain : domain;
            orgCode = (!!from.orgCode) ? from.orgCode : orgCode;
            createTime = (!!from.createTime) ? from.createTime : createTime;
            updateTime = (!!from.updateTime) ? from.updateTime : updateTime;
            invalid = (!!from.invalid) ? from.invalid : invalid;
            collType = (!!from.collType) ? from.collType : collType;
            lowPrice = (!!from.lowPrice) ? from.lowPrice : lowPrice;
            isChange = (!!from.isChange) ? from.isChange : isChange;
            isTestProduct = (!!from.isTestProduct) ? from.isTestProduct : isTestProduct;
            codSignRate = (!!from.codSignRate) ? from.codSignRate : codSignRate;
            onlineSignRate = (!!from.onlineSignRate) ? from.onlineSignRate : onlineSignRate;
            preSignRate = (!!from.preSignRate) ? from.preSignRate : preSignRate;
            otherRate = (!!from.otherRate) ? from.otherRate : otherRate;
            highLine = (!!from.highLine) ? from.highLine : highLine;
            midLine = (!!from.midLine) ? from.midLine : midLine;
            lowLine = (!!from.lowLine) ? from.lowLine : lowLine;
            amountPerCustomer = (!!from.amountPerCustomer) ? from.amountPerCustomer : amountPerCustomer;
            countPerCustomer = (!!from.countPerCustomer) ? from.countPerCustomer : countPerCustomer;
            roi = (!!from.roi) ? from.roi : roi;
            buyRate = (!!from.buyRate) ? from.buyRate : buyRate;
            buyPrice = buyRate/100 * orderAmount;
            adRate = (!!from.adRate) ? from.adRate : adRate;
            logisticsRate = (!!from.logisticsRate) ? from.logisticsRate : logisticsRate;
            logisticsFee = logisticsRate/100 * orderAmount;
            threeRate = (!!from.threeRate) ? from.threeRate : threeRate;
            hisSignRate = (!!from.hisSignRate) ? from.hisSignRate : hisSignRate;
            signRate = (!!from.signRate) ? from.signRate : signRate;
            profitRate = (!!from.profitRate) ? from.profitRate : profitRate;
            profitRateResult = (!!from.profitRateResult) ? from.profitRateResult : profitRateResult;
            lowestRoi = (!!from.lowestRoi) ? from.lowestRoi : lowestRoi;
            aderName = (!!from.aderName) ? from.aderName : aderName;
            chooserName = (!!from.chooserName) ? from.chooserName : chooserName;
            avgAdCost = (!!from.avgAdCost) ? from.avgAdCost : avgAdCost;
            adCriticalVal = (!!from.adCriticalVal) ? from.adCriticalVal : adCriticalVal;
            originAvgAdCost = (!!from.originAvgAdCost) ? from.originAvgAdCost : originAvgAdCost;
            originAdCriticalVal = (!!from.originAdCriticalVal) ? from.originAdCriticalVal : originAdCriticalVal;
            exchangeRate = (!!from.exchangeRate) ? from.exchangeRate:1;
            currencySign = (!!from.currencySign) ? from.currencySign:'';
        }
    }

    function reCalc() {
        amountPerCustomer = amountPerCustomer1();
        countPerCustomer = countPerCustomer1();
        roi = roi1();
        buyRate = buyRate1();
        adRate = adRate1();
        logisticsRate = logisticsRate1();
        threeRate = threeRate1();
        signRate = signRate2();
        profitRateResult = calcProfitRateResult();
        lowestRoi = calcLowestRoi();
        profitRate = calcProfitRate();
        avgAdCost = avgAdCost1();
        adCriticalVal = adCriticalVal1();
        profitRatePre = profitRatePre1();
        // originAvgAdCost = avgAdCost1Origin();
        // originAdCriticalVal = adCriticalVal1Origin()
    }

    function split() {
        let orderPct = 0;
        if (orderCount1() > 0) {
            orderPct = _targetAdsetPurchase / orderCount1();
        }
        orderCount = _targetAdsetPurchase;
        firstSendQty *= orderPct;
        secondSendQty *= orderPct;
        buyPrice *= orderPct;
        adCost = _targetAdsetAdCost;
        orderAmount *= orderPct;
        logisticsFee *= orderPct;
    }


    //客单价
    function amountPerCustomer1() {
        let orderCount = orderCount1();
        if (orderCount === 0) return 0;
        return orderAmount / orderCount;
    }

    //客单量
    function countPerCustomer1() {
        let orderCount = orderCount1();
        if (orderCount === 0) return 0;
        return quantity() / orderCount;
    }

    //ROI
    function roi1() {
        if (adCost <= 0) return 0;
        return orderAmount / adCost;
    }

    //buy_rate
    function buyRate1() {
        if (orderAmount === 0) return 0;
        let buyCost = buyPrice ;
        return (buyCost / orderAmount) * 100;
    }

    //ad_rate
    function adRate1() {
        if (orderAmount === 0) return 0;
        return (adCost / orderAmount) * 100;
    }

    //logisticsRate
    function logisticsRate1() {
        if (orderAmount === 0) return 0;
        let fee = logisticsFee;
        return (fee / orderAmount) * 100;
    }

    //three_rate


    function threeRate1() {
        return buyRate1()
            + (adRate1())
            + (logisticsRate1());
    }

    /**
     * @param codSignRate    到付签收率已乘100
     * @param onlineSignRate 在线支付签收率已乘100
     * @param preSignRate    预测签收率已乘100
     * @return 签收率
     */
    function signRate2() {
        return signRate;
    }


    //利润率结果
    function calcProfitRateResult() {
        if (orderCount1() === 0) return "LOW2";
        let profitRate = calcProfitRate();
        if (profitRate < lowLine) {
            return "LOW2";
        } else if (midLine && profitRate < midLine) {
            return "LOW";
        } else if (profitRate < highLine) {
            return "MID";
        } else {
            return "HIGHT";
        }
    }

    //roi临界点
    function calcLowestRoi() {
        let decimal = (
            signRate2()
            - buyRate1()
            - (logisticsRate1())
            - (highLine)
        ) / 100;
        if (decimal === 0) {
            return 0;
        } else {
            return 1 / decimal;
        }
    }

    //利润率
    function calcProfitRate() {
        if (orderAmount === 0) return 0;
        return signRate2()
            - (threeRate1())
            - (otherRate);
    }

    function quantity() {
        // return firstSendQty + secondSendQty;
        return orderCount * countPerCustomer
    }

    function orderCount1() {
        return orderCount;
    }

    //平均广告费=广告费/订单量（订单量为0的也会抓取广告费）；
    function avgAdCost1() {
        if (orderCount1() === 0) return adCost;
        return adCost / orderCount1();
    }
    // 平均广告费原始币种
    function avgAdCost1Origin(){
        return avgAdCost1() * this.exchangeRate;
    }

    //毛利率
    function profitRatePre1() {
        if (orderAmount === 0) return 0;
        return signRate2()
            - (threeRate1());
    }

    //广告临界值=客单价/roi临界值
    function adCriticalVal1() {
        let lowestRoi = calcLowestRoi();
        if (lowestRoi === 0) return 0;
        return amountPerCustomer1() / lowestRoi;
    }
    // 广告临界值原始币种
    function adCriticalVal1Origin() {
        return adCriticalVal1() * this.exchangeRate;
    }

    init(from);
    this.productId = productId;
    this.collId = collId;
    this.orderDate = orderDate;
    this.orderCount = orderCount;
    this.aderId = aderId;
    this.firstSendQty = firstSendQty;
    this.secondSendQty = secondSendQty;
    this.platform = platform;
    this.buyPrice = buyPrice;
    this.adCost = adCost;
    this.orderAmount = orderAmount;
    this.id = id;
    this.areaId = areaId;
    this.goodsId = goodsId;
    this.goodsName = goodsName;
    this.productName = productName;
    this.category = category;
    this.categoryId = categoryId;
    this.productType = productType;
    this.currencyId = currencyId;
    this.chooserId = chooserId;
    this.designerId = designerId;
    this.designerName = designerName;
    this.domain = domain;
    this.orgCode = orgCode;
    this.createTime = createTime;
    this.updateTime = updateTime;
    this.invalid = invalid;
    this.collType = collType;
    this.lowPrice = lowPrice;
    this.isChange = isChange;
    this.isTestProduct = isTestProduct;
    this.codSignRate = codSignRate;
    this.onlineSignRate = onlineSignRate;
    this.preSignRate = preSignRate;
    this.logisticsFee = logisticsFee;
    this.otherRate = otherRate;
    this.highLine = highLine;
    this.midLine = midLine;
    this.lowLine = lowLine;
    this.amountPerCustomer = amountPerCustomer;
    this.countPerCustomer = countPerCustomer;
    this.roi = roi;
    this.buyRate = buyRate;
    this.adRate = adRate;
    this.logisticsRate = logisticsRate;
    this.threeRate = threeRate;
    this.hisSignRate = hisSignRate;
    this.signRate = signRate;
    this.profitRate = profitRate;
    this.profitRateResult = profitRateResult;
    this.lowestRoi = lowestRoi;
    this.aderName = aderName;
    this.chooserName = chooserName;
    this.avgAdCost = avgAdCost;
    this.adCriticalVal = adCriticalVal;
    this.grossMarginRate = profitRatePre;
    this.profitRatePre = profitRatePre.toFixed(2);
    this.originAvgAdCost = (avgAdCost / exchangeRate).toFixed(2);
    this.originAdCriticalVal = (adCriticalVal / exchangeRate).toFixed(2);
    this.currencySign = currencySign;
}

// //test
// let p1 = new ProductReport(
//     {
//         "continuousAlarmDays": null,
// 				"productId": 1003328907,
// 				"collId": 1002332013,
// 				"orderDate": null,
// 				"aderId": 0,
// 				"aderName": null,
// 				"firstSendQty": 60,
// 				"secondSendQty": 0,
// 				"platform": "facebook",
// 				"buyPrice": 228.0,
// 				"adCost": 1389.39,
// 				"orderAmount": 2606.56,
// 				"id": null,
// 				"areaId": 0,
// 				"areaName": null,
// 				"goodsId": 1026976,
// 				"goodsName": "JS-【三阶段缓解】化唐消穴位贴",
// 				"productName": null,
// 				"category": null,
// 				"categoryId": 0,
// 				"productType": null,
// 				"currencyId": 0,
// 				"chooserId": 0,
// 				"chooserName": null,
// 				"designerId": 0,
// 				"designerName": null,
// 				"domain": null,
// 				"orgCode": null,
// 				"createTime": null,
// 				"updateTime": null,
// 				"invalid": 0,
// 				"collType": 0,
// 				"lowPrice": 0,
// 				"isChange": 0,
// 				"isTestProduct": 0,
// 				"codSignRate": null,
// 				"onlineSignRate": null,
// 				"preSignRate": null,
// 				"otherRate": 8.0,
// 				"highLine": 7.0,
// 				"midLine": 2.0,
// 				"lowLine": -1.0,
// 				"amountPerCustomer": 372.37,
// 				"countPerCustomer": 8.57,
// 				"roi": 1.88,
// 				"buyRate": 8.75,
// 				"adRate": 53.3,
// 				"logisticsRate": 17.17,
// 				"threeRate": 79.22,
// 				"hisSignRate": null,
// 				"signRate": 97.29,
// 				"profitRate": 10.07,
// 				"profitRateResult": "HIGHT",
// 				"lowestRoi": 1.77,
// 				"avgAdCost": 198.48,
// 				"adCriticalVal": 209.92,
// 				"profitRatePre": 18.07
//     },
//     10, 5, 44, 14
// )
// console.log(p1);