<template>
  <div class="profitRateResultList">
    <el-row>
      <el-col :span="8">
        <div class="grid-content bg-purple-light">商品名称</div>
        <div class="grid-content">
          <el-tooltip
            :content="formatV1('productName')"
            placement="top"
            effect="dark"
          >
            <span>{{ formatV1("productName") }}</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-light">产品名称</div>
        <div class="grid-content">
          <el-tooltip
            :content="formatV1('goodsName')"
            placement="top"
            effect="dark"
          >
            <span>{{ formatV1("goodsName") }}</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-light">商品域名</div>
        <div class="grid-content">
          <el-tooltip
            :content="formatV1('domain')"
            placement="top"
            effect="dark"
          >
            <span>{{ formatV1("domain") }}</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">商品ID</div>
        <div class="grid-content">{{ formatV1("productId") }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">产品ID</div>
        <div class="grid-content">{{ formatV1("goodsId") }}</div>
      </el-col>
      <!-- </el-row>
                    <el-row> -->
      <el-col :span="3">
        <div class="grid-content bg-purple-light">订单量</div>
        <div class="grid-content">
          {{
            productReport.codOrderCount + productReport.onlineOrderCount || "--"
          }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">客单价</div>
        <div class="grid-content">
          <!-- {{ formatV1("amountPerCustomer") }} -->
          {{ decimalFix("amountPerCustomer", 2) }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">ROI</div>
        <!-- <div class="grid-content">{{ formatV1("roi") }}</div> -->
        <div class="grid-content">{{ decimalFix("roi", 2) }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">ROI临界点</div>
        <!-- <div class="grid-content">{{ formatV1("lowestRoi") }}</div> -->
        <div class="grid-content">{{ decimalFix("lowestRoi", 2) }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">签收率</div>
        <div class="grid-content">{{ formatV1("signRate") }}</div>
      </el-col>
      <!-- </el-row>
                    <el-row> -->
      <el-col :span="3">
        <div class="grid-content bg-purple-light">选品人</div>
        <div class="grid-content">
          {{ valNameOrId("chooser") }}
        </div>
      </el-col>
      <!-- <el-col :span="3">
        <div class="grid-content bg-purple-light">优化师</div>
        <div class="grid-content">
          {{ valNameOrId("ader") }}
        </div>
      </el-col> -->
      <el-col :span="3">
        <div class="grid-content bg-purple-light">设计师</div>
        <div class="grid-content">
          {{ valNameOrId("designer") }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">采购占比</div>
        <div class="grid-content">
          <!-- {{ valCarryPercent("buyRate") }} -->
          {{ decimalFix("buyRate", 2) }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">运费占比</div>
        <div class="grid-content">
          <!-- {{ valCarryPercent("logisticsRate") }} -->
          {{ decimalFix("logisticsRate", 2) }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">广告费占比</div>
        <div class="grid-content">
          <!-- {{ valCarryPercent("adRate") }} -->
          {{ decimalFix("adRate", 2) }}
        </div>
      </el-col>
      <!-- </el-row>
                    <el-row> -->
      <el-col :span="3">
        <div class="grid-content bg-purple-light">
          <el-tooltip content="三项成本占比" placement="top" effect="dark">
            <span>三项成本占比</span>
          </el-tooltip>
        </div>
        <div class="grid-content">
          {{ valCarryPercent("threeRate") }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">
          <el-tooltip
            content="其他(含退款/经营成本)"
            placement="top"
            effect="dark"
          >
            <span>其他(含退款/经营成本)</span>
          </el-tooltip>
        </div>
        <div class="grid-content">{{ formatV1("otherRate") }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">经营评估</div>
        <div class="grid-content">
          <img
            src="../../../../assets/icon/level1.png"
            alt=""
            v-if="productReport.profitRateResult == 'HIGHT'"
          />
          <img
            src="../../../../assets/icon/level2.png"
            alt=""
            v-if="productReport.profitRateResult == 'MID'"
          />
          <img
            src="../../../../assets/icon/level3.png"
            alt=""
            v-if="productReport.profitRateResult == 'LOW'"
          />
          <img
            src="../../../../assets/icon/level4.png"
            alt=""
            v-if="productReport.profitRateResult == 'LOW2'"
          />
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">客单量</div>
        <div class="grid-content">
          <!-- {{ formatV1("countPerCustomer") }} -->
          {{ decimalFix("countPerCustomer", 1) }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">平均广告费</div>
        <div class="grid-content">
          {{ decimalFix("avgAdCost", 2) }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">广告临界值</div>
        <div class="grid-content">
          {{ decimalFix("adCriticalVal", 2) }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">毛利率</div>
        <div class="grid-content">
          {{ valCarryPercent("profitRatePre") }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">毛利率</div>
        <div class="grid-content">
          {{productReport.continuousAlarmDays }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-light">连续警告</div>
        <div class="grid-content">
          {{productReport.lossDays }}
        </div>
      </el-col>
      <!-- <el-col :span="12">
        <div class="grid-content bg-purple-light">设计</div>
        <div class="grid-content">
          <el-tooltip
            :content="formatTip('designer')"
            placement="top"
            effect="dark"
          >
            <span>{{ valNameOrId("designer") }}</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-light">团队</div>
        <div class="grid-content">
          <el-tooltip
            :content="formatTip('area')"
            placement="top"
            effect="dark"
          >
            <span>{{ valNameOrId("area") }}</span>
          </el-tooltip>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
export default {
  name:"productReportList",
  props: {
    productReport: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formatV1() {
      return function (key) {
        return this.productReport[key] || "--";
      };
    },
    valNameOrId() {
      return function (key) {
        return (
          this.productReport[`${key}Name`] ||
          this.productReport[`${key}Id`] ||
          "--"
        );
      };
    },
    formatTip() {
      return function (key) {
        return (
          this.productReport[`${key}Name`] ||
          String(this.productReport[`${key}Id`]) ||
          "--"
        );
      };
    },
    valCarryPercent() {
      return function (key) {
        return this.productReport[key] ? this.productReport[key].toFixed(2) + "%" : "--";
      };
    },
    decimalFix() {
      return function (key, n) {
        let val = this.productReport[key];
        return val ? parseFloat(val).toFixed(n) : 0;
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.profitRateResultList {
  .el-row {
    border: 0.5px solid #ddd;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    line-height: 40px;
    padding: 0 5px;
    border: 0.5px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    &.lh {
      line-height: 20px;
      overflow: inherit;
      text-overflow: visible;
      white-space: pre-wrap;
    }
    // &:hover{
    //     text-overflow: visible;
    //     // animation: 3s siderbar linear infinite normal;
    //     overflow:inherit;
    // }
    @keyframes siderbar {
      0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
      }
      100% {
        transform: translateX(-45px);
        -webkit-transform: translateX(-45px);
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
.profitRateResult {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>